import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import '../Styles/vendor-sign-up.css';
import React from 'react';
import cardimage1 from '../Assets/register.svg';
import cardimage2 from '../Assets/pass.svg';
import cardimage3 from '../Assets/careers.svg';
import CardGroup from 'react-bootstrap/CardGroup';
import slide1 from '../Assets/event.svg';
import slide2 from '../Assets/variety.svg';
import slide3 from '../Assets/rewards.svg';
import Carousel from 'react-bootstrap/Carousel';

const VendorSignUp = () => {

  return (
    <div className="vendor-sign-up">
      <div className="vendor-sign-up-banner">
        <div className="vendor-sign-up-form">
        <Row sm={1} md={1} lg={2}>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Get Started</Card.Title>
                <Card.Text>
                  <Form>
                    <Form.Group className="mb-3">
                      <Form.Label>Store Address</Form.Label>
                      <Form.Control type="text"/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Store Number (Optional)</Form.Label>
                      <Form.Control type="text"/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Store Name</Form.Label>
                      <Form.Control type="text" placeholder= "Example: Joe's Pizza - 123 Main Street"/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Brand Name</Form.Label>
                      <Form.Control type="text" placeholder= "Example: Joe's Pizza"/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Cuisine Type(s)</Form.Label>
                      <Form.Control type="text" placeholder= "Example: Joe's Pizza"/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control type="text"/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control type="text"/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email"/>
                    </Form.Group>
                    <Button href="/vendor" className='login-button' size="lg" variant="primary" type="submit">
                      Sign Up
                    </Button>
                  </Form>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        </div>
        </div>
        <Row>
        <div className="card-container">
                <CardGroup>
                    <Card>
                        <Card.Img className='card-image' variant="top" src={cardimage1} />
                        <Card.Body>
                            <Card.Title>Boost Your Food Brand's Growth</Card.Title>
                            <Card.Text>
                            Accelerate the growth of your food brand by leveraging our comprehensive services, designed to enhance your visibility and attract new customers and event planners. Through our platform, your brand will flourish and gain traction in the market.
                            </Card.Text>
                            <Button className='card-button' variant="primary">Learn More</Button>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Img className='card-image' variant="top" src={cardimage2} />
                        <Card.Body>
                            <Card.Title>Tap into the Growing Online Ordering Market</Card.Title>
                            <Card.Text>
                            Expand your online ordering business by capitalizing on the rising demand for catering food. Our platform provides you with access to a market that is continuously growing, ensuring that you can meet the increasing needs of customers seeking catering services.
                            </Card.Text>
                            <Button className='card-button' variant="primary">Join Now</Button>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Img className='card-image' variant="top" src={cardimage3} />
                        <Card.Body>
                            <Card.Title>Extend Your Customer Reach with Our Platform</Card.Title>
                            <Card.Text>
                            Leverage our platform to broaden your customer reach and connect with individuals from diverse geographical areas. By showcasing your business on our platform, you can benefit from effective and free marketing, as you only pay fees based on actual item sales. Embrace the opportunity to reach a wider audience and maximize your business's visibility and profitability.
                            </Card.Text>
                            <Button className='card-button' variant="primary">Apply Today</Button>
                        </Card.Body>
                    </Card>
                </CardGroup>
            </div>
        </Row>
        <Carousel variant="dark">
                <Carousel.Item>
                    <div className="carousel-slide">
                        <div className="carousel-info">
                            <h2 className="carousel-heading">Cater for any occasion</h2>
                            <p className="carousel-text">Our chefs are ready to cater for your needs. Catering for any party size, cuisine type and more. </p>
                        </div>
                        <img
                            className="carousel-image"
                            src={slide1}
                            alt="First slide"
                        />
                    </div>
                    {/* <Carousel.Caption>
            <h2>Help grow your food brand</h2>
            <p>Through our service your brand will grow and gain traction from new customers and event planners. </p>
          </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item>
                    <div className="carousel-slide">
                        <div className="carousel-info">
                            <h2 className="carousel-heading">Better variety, more convenience</h2>
                            <p className="carousel-text">All your favourite caterers in one place allowing you to compare and choose with the most convenience. </p>
                        </div>
                        <img
                            className="carousel-image"
                            src={slide2}
                            alt="Second slide"
                        />
                    </div>
                    {/* <Carousel.Caption>
            <h2>Expand your online ordering business</h2>
            <p>Tap into a market with increasing demand for catering food. </p>
          </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item>
                    <div className="carousel-slide">
                        <div className="carousel-info">
                            <h2 className="carousel-heading">Chef Katers Rewards</h2>
                            <p className="carousel-text">
                                Be part of the CK community to get rewarded and save you more money when ordering food.
                            </p>
                        </div>
                        <img
                            className="carousel-image"
                            src={slide3}
                            alt="Third slide"
                        />
                    </div>
                    {/* <Carousel.Caption>
            <h2>Increased customer reach using our platform</h2>
            <p>
            Allow people from different areas to see your business and only pay on item sales, who doesn’t love free marketing?
            </p>
          </Carousel.Caption> */}
                </Carousel.Item>
            </Carousel>
      </div>
  );
};

export default VendorSignUp;