import '../Styles/vendor.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faRectangleList, faBookOpenReader, faCommentDollar, faIdCard, faClock, faMoneyCheckDollar, faHeadset, faGear } from "@fortawesome/free-solid-svg-icons"

const Vendor = () => {
  return (
    <div className="vendor-portal">
      <Row>
        <Col className='nav-links'>
          <ul>
            <li><FontAwesomeIcon icon={faHouse} />Home</li>
            <li><FontAwesomeIcon icon={faRectangleList} />Order Summary</li>
            <li><FontAwesomeIcon icon={faBookOpenReader} />Menu</li>
            <li><FontAwesomeIcon icon={faCommentDollar} />Marketing</li>
            <li><FontAwesomeIcon icon={faIdCard} />Profile</li>
            <li><FontAwesomeIcon icon={faClock} />Hours of Operation</li>
            <li><FontAwesomeIcon icon={faMoneyCheckDollar} />Payment</li>
            <li><FontAwesomeIcon icon={faHeadset} />Support</li>
          </ul>
        </Col>
        <Col>
          <div className='vendor-view'>
            <div className="banner">
            </div>
            <div className="info">
              <p>
                Bonton, Indian Catering | Milton, ON (14.3KM)
              </p>
              <p>
                Minimum 4 hours notice | Order size: 20-500
              </p>
              <Button> <FontAwesomeIcon icon={faGear} /> Edit Profile</Button>
            </div>
            <div className='info-cards'>
            <Row>
              <Col>
                <span>Top Selling Items</span>
              </Col>
              <Col>
                <span>Menu Item Feedback</span>
              </Col>
              <Col>
                <span>Operations</span>

              </Col>
            </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Vendor;