import React from 'react';
import NavBar from './Layout/navbar';
import Home from './Layout/home';
import Login from './Layout/login'
import GetStarted from './Layout/getstarted';
import Results from './Layout/results';
import Footer from './Layout/footer';
import AboutUs from './Layout/aboutus';
import Team from './Layout/team';
import Locations from './Layout/locations';
import Careers from './Layout/careers';
import RegisterRestaurant from './Layout/registerrestaurant';
import Partner from './Layout/partner';
import CkPass from './Layout/ckpass';
import CxSupport from './Layout/cxsupport';
import Restaurant from './Layout/restaurant';
import VendorSignUp from './Layout/vendor-sign-up';
import Vendor from './Layout/vendor';
import './Styles/App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'

function App() {
  return (
    <div className="App">
      <Router>
      <NavBar />
      <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/results' element={<Results/>} />
          <Route path='/login' element={<Login/>} />
          <Route path='/get-started' element={<GetStarted/>} />
          <Route path='/about-us' element={<AboutUs/>} />
          <Route path='/meet-the-team' element={<Team/>} />
          <Route path='/locations' element={<Locations/>} />
          <Route path='/careers' element={<Careers/>} />
          <Route path='/restaurant-registration' element={<RegisterRestaurant/>} />
          <Route path='/partner-with-us' element={<Partner/>} />
          <Route path='/ck-pass' element={<CkPass/>} />
          <Route path='/customer-support' element={<CxSupport/>} />
          <Route path='/restaurant' element={<Restaurant/>} />
          <Route path='/vendor-sign-up' element={<VendorSignUp/>} />
          <Route path='/vendor' element={<Vendor/>} />
      </Routes>
      <Footer />
      </Router>
    </div>
  );
}

export default App;
